import styled from 'styled-components';

// const BoldFont = 'Rubik-Bold';

const FieldContainer = styled.div`
  width: 100%;
  float: left;
  padding: 25px 38px 25px 40px;
`;

const InviteAmigo = styled.div`
  float: left;
  width: ${({width})=> width ? width : 'calc(100% / 3)'};
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: ${({ hidden }) => hidden && 'none'}
  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    object-fit: cover;
  }
  
  label {
    padding-left: 10px;
    margin-top: -12px;
  }
  .showInfoText{
    display: none;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    position: absolute;
    width: 270px;
    background: white;
    border-radius: 6px;
    height: 80px;
    z-index: 9999;
    top: -68px;
    left: 40px;
    padding: 10px;
    font-family: Rubik-Regular;
    &::after {
      width: 10px;
      height: 10px;
      background: #fff;
      position: absolute;
      content: '';
      bottom: -4px;
      left: 6%;
      transform: rotate(-45deg);
    }
    @media(max-width: 400px) {
      width: 250px;
    }
  }
  &:hover{
    .showInfoText{
      display: block;
    }
  }
  @media only screen and (max-width: 767px) {
    
    > img {
      margin-left: 15px;
    }
  }
`;

export { FieldContainer, InviteAmigo };
